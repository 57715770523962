#homeNav{
    scroll-margin-top: 150px;
}

.container-fluid{
    background-image: url('/public/images/hero-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-container{
    width: 600px;
    height: 300px;
    background-color: rgb(255, 255, 255, 0.7);    
    display: flex;
    align-items: center;
    justify-content: center;
}

.title{
    font-size: 4.5rem;
    letter-spacing: 2.5px;
}

@media screen and (max-width:1500px) {
    .title-container{
        width: 500px;
        height: 250px;
    }

    .title{
        font-size: 4rem;
    }
}

@media screen and (max-width:1100px){
    .container-fluid{
        height: 40vh;
    } 

    .title-container{
        width: 300px;
        height: 150px;
    }

    .title{
        font-size: 2rem;
    }
}

@media screen and (max-width:900px){
    .container-fluid{
        height: 40vh;
    } 

    .title-container{
        width: 250px;
        height: 125px;
    }

    .title{
        font-size: 1.75rem;
    }
}