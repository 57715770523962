.classes{
    padding: 7% 10%;
    width: 100%;
    margin: 0;
}

.heading{
    text-align: center;
    margin-bottom: 50px;
    font-size: 3rem;
    font-weight: 500;
}

.cards-container{
    display: grid;
    grid-template-columns: 1.3334fr 1.3334fr 1.3334fr;
    width: 100%;
    place-items: center;
}

.children-classes{
    scroll-margin-top: 150px;
}

.adult-classes{
    /* margin-top: 5%; */
    scroll-margin-top: 150px;
}

.cards-container-mobile{
    display: none;
}

@media screen and (max-width:1370px){
    .cards-container{
        grid-template-columns: 2fr 2fr;
    }
}

@media screen and (max-width:920px) {

    .heading{
        margin-bottom: 0;
        font-size: 2.5rem;
    }

    .cards-container{
        display: none;
    }

    .cards-container-mobile{
        display: block;
        
    }

    .cards-carousel{
        padding: 7% 12% 10%;
    }
}

@media screen and (max-width:920px){
    .heading{
        font-size: 2rem;
    }
}