:root {
    --button-width: 175px;
}

.widget-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7% 10% 0;
    width: 100%;
    margin: 0;
}

.widget-container > header {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tabs{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(200, 195, 192, 0.25);
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
}

.tabs > button {
    padding: 4px 0px;
    width: var(--button-width);
    cursor: pointer;
    background: transparent;
    display: grid;
    place-items: center;
    border: 0;
    border-radius: 0;
    font-size: 22px;
    transition: 0.25s;
    margin: 0;
    color: black;
    border-radius: 10px;
}

.tabs > button:hover{
    color: black;
    background-color: rgba(195, 188, 183, 0.25);
}

.tabs > button:not(.active) {
    opacity: 0.7;
}

.tabs > button:hover:not(.active) {
    opacity: 1;
}

.tabs > button.active{
    background-color: rgb(255, 102, 0, 0.1);
}

@media screen and (max-width:1500px) {

    :root {
        --button-width: 150px;
    }

    .tabs > button {
        font-size: 18px;
        padding: 3px 0px;
    }
}

@media screen and (max-width:1200px) {

    :root {
        --button-width: 130px;
    }

    .tabs > button {
        font-size: 15px;
        padding: 3px 0px;
    }
}

@media screen and (max-width:900px){

    :root {
        --button-width: 110px;
    }

    .tabs > button {
        font-size: 13px;
        padding: 2px 0px;
    }

    .widget-container{
        padding: 5% 5%;
    }

}

@media screen and (max-width:600px){

    :root {
        --button-width: 900px;
    }

    .tabs > button {
        font-size: 9px;
        padding: 2px 0px;
    }

    .widget-container{
        padding: 5% 5%;
    }

}