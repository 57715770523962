.footer-container{
    width: 100%;
    background-color: rgb(255, 102, 0, 0.1);
    margin: 7% 0 0;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15%;
}

.footer-icon{
    width: 25px;
    height: 25px;
    padding: 7px;
    background-color: black;
    color: white;
    border-radius: 50%;
    margin-right: 10px;
}

.footer-icon:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.footer-logo img{
    width: 100%;
}

.footer-contact-info p{
    text-align: left;
    margin: 0;
    font-size: 1.35rem;
}

.footer-btn button{
    width: 100%;
    margin: 20px 0 0;
    background-color: transparent;
}

.footer-btn button:hover{
    background-color: rgb(255, 102, 0, 0.8);;
}

@media screen and (max-width:1600px){
    .footer-left{
        width: 250px;
    }

    .footer-contact-info p{
        font-size: 1.15rem;
    }

    .footer-icon{
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width:1000px){
    .footer-left{
        width: 200px;
    }

    .footer-contact-info p{
        font-size: 1rem
    }

    .footer-icon{
        width: 15px;
        height: 15px;
    }
}

@media screen and (max-width:1000px){
    .footer-left{
        width: 200px;
    }

    .footer-contact-info p{
        font-size: 1rem
    }

    .footer-icon{
        width: 15px;
        height: 15px;
    }
}

@media screen and (max-width:565px){

    .footer-left{
        width: 130px;
    }

    .footer-contact-info p{
        font-size: 0.7rem
    }

    .footer-icon{
        width: 12px;
        height: 12px;
    }
}