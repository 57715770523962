.about-container{
    margin: 0 10% 5%;
    display: flex;
    border-radius: 15px;
    padding: 2%;
    background-color: rgb(255, 102, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
    gap: 50px;
    align-items: center;
    scroll-margin-top: 150px;
}

.about-img-container{
    width: 30%;
}

.about-img-container img{
    width: 100%;
}

.about-content{
    width: 70%;
}

.bio-mobile{
    display: none;
}

.about-content h1{
    font-size: 2.5rem;
}

.about-content p{
    font-size: 1.3rem;
    padding-left: 15px;
    text-align: justify;
}

@media screen and (max-width: 1700px){
    .about-content h1{
        font-size: 2rem;
    }

    .about-content p{
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 1300px){
    .about-container{
        display: block;
    }

    .about-img-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .about-img-container img{
        width: 40%;
    }

    .about-content{
        width: 100%;
        padding: 5% 5% 2%;
    }

    .about-content p{
        font-size: 1.3rem;
        padding-left: 10px;
    }
}

@media screen and (max-width: 800px){
    .about-img-container img{
        width: 60%;
    }

    .about-content p{
        font-size: 1.1rem;
        padding-left: 5px;
    }

    .bio-para{
        display: none;
    }

    .bio-mobile{
        display: block;
    }

    .bio-mobile{
        text-align: center;
    }

    .bio-mobile h2{
        margin: 1%;
    }

    .bio-mobile h3{
        font-size: 1.1rem;
        margin: 1%;
    }

}