html,
body {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    position: relative;
}

.heading-font {
    font-family: 'Roboto Serif', serif;
}
.para-font {
    font-family: 'PT Sans', sans-serif;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.orange-button {
    font-size: 1.5rem;
    width: 70%;
    padding: 2%;
    margin: 0 0 7%;
    border: none;
    background-color: white;
    color: rgb(255, 102, 0);
    border: 1px solid rgb(255, 102, 0);
    border-radius: 10px;
}

.orange-button:hover {
    color: white;
    background-color: rgb(255, 102, 0, 0.8);
}

@media screen and (max-width: 700px){
    .orange-button{
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 450px) {
    .orange-button{
        font-size: 1rem;
    }
}