.class-detail {
    padding: 4% 7%;
}

.details-container {
    display: flex;
}

.details-img-container {
    width: 40%;
}

.details-img {
    width: 40%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
}

.details-content {
    border-radius: 15px;
    padding: 2%;
    background-color: rgb(255, 102, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
    margin-left: 2%;
}

.details-content h1 {
    font-size: 2.1rem;
    font-weight: 600;
}

.detail-text-container {
    font-size: 1.5rem;
    padding: 0 15px;
    text-align: justify;
    max-height: 50vh;
    overflow-y: auto;
}

.detail-text-container::-webkit-scrollbar {
    width: 6px;
}

.detail-text-container::-webkit-scrollbar-thumb {
    background-color: rgb(255, 69, 0, 0.8);
    border-radius: 20px;
}

.detail-text-container::-webkit-scrollbar-track {
    background-color: rgb(255, 102, 0, 0.4);
    border-radius: 20px;
}

.features-container {
    display: flex;
    flex-wrap: wrap;
    gap: 7px 2%;
    margin: 2% 0 5% 2%;
}

.schedule-img {
    width: 70%;
    padding: 7% 0 0;
}

.video-container {
    position: relative;
    width: 80%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.yt-vid {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 80%;
    border-radius: 20px;
    border: none;
}

@media screen and (max-width:1600px) {
    .details-content h1 {
        font-size: 1.8rem;
    }

    .detail-text-container {
        font-size: 1.1rem;
        max-height: 45vh;
    }

    .schedule-img {
        width: 80%;
    }
}

@media screen and (max-width:1300px) {
    .details-content h1 {
        font-size: 1.5rem;
    }

    .detail-text-container {
        font-size: 0.8rem;
        max-height: 35vh;
    }
}

@media screen and (max-width:900px) {
    .details-container {
        display: flex;
        flex-direction: column;
    }

    .details-content {
        margin: 5% 0;
    }

    .details-img {
        width: 100%;
        margin: auto;
    }

    .schedule-img {
        width: 90%;
    }
}