.nav-container{
    width: 100%;
    position: sticky;
    z-index: 999;
    background-color: white;
    top: 0;
}

nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
}

#nav-logo{
    width: 75px;
}

#nav-logo img{
    width: 75px;
}

.nav-list-cont{
    width: 100%;
}

.nav-list{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
}

.nav-list li
{
    text-align: center;
    list-style: none;
    padding: 10px 20px 0;
    margin: 25px 0;
    position: relative;
}

.nav-list-mob li{
    list-style: none;
    padding: 20px 20px 0;
    margin: 3% 0;
    position: relative;
}

.nav-list li a,
.nav-list-mob li a{
    text-decoration: none;
    font-size: 1.1rem;
    color: black;
    font-family: 'Roboto Serif', serif;
    transition: 0.2s ease-in-out;
}

.nav-list li a:hover,
.nav-list li a:active,
.nav-list-mob li a:hover,
.nav-list-mob li a:active
{
    color: rgb(255, 102, 0);
}

.nav-list li a:hover::after,
.nav-list li a:active::after,
.nav-list-mob li a:active::after,
.nav-list-mob li a:hover::after
{
    content: "";
    width: 30%;
    height: 2px;
    background: rgb(255, 102, 0);
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile{
    display: none;
}

.nav-list-cont-mob{
    width: 100%;
    position: absolute;
    height: auto;
    right: -110%;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(5px);
    transition: all 0.3s ease-in-out;
}

.nav-list-cont-mob.active{
    right: 0;
}

.nav-list-mob{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100vh;
}

@media screen and (max-width:1100px){

    nav{
        padding: 15px 80px 5px;
    }

    .nav-list-cont{
        display: none;
    }

    #mobile{
        display: block
    }

    #nav-logo{
        width: 70px;
    }
    
    #nav-logo img{
        width: 100%;
    }

}

@media screen and (max-width:800px){
    nav{
        padding: 10px 40px 2px;
    }

    #nav-logo{
        width: 50px;
    }
}