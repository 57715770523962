.card{
    width: 90%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
    margin: 0 auto 35px;
    overflow: hidden;
    background-color: transparent;
}

.card:hover{
    cursor: pointer;
}

.card:hover .card-img{
    transform: scale(1.05);
}

.card-body{
    text-align: center;
}

.card-img{
    transition: transform .2s;
}

.card-img img{
    width: 100%;
    border-radius: 10px 10px 0 0;
    margin: 0;
}

.card-heading{
    font-size: 1.75rem;
    font-weight: 600;
    color: black;
}

.card-subtext{
    font-size: 1.25rem;
    font-weight: 600;
    color: black;
    margin: 0;
}

@media screen and (max-width: 1600px) {
    .card-heading{
        font-size: 1.5rem;
    }

    .card-subtext{
        font-size: 1rem;
    }
}

@media screen and (max-width: 700px) {
    .card-heading{
        font-size: 1.25rem;
    }

    .card-subtext{
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 450px) {
    .card-heading{
        font-size: 1rem;
    }

    .card-subtext{
        font-size: 0.5rem;
    }
}