@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.gallery-slider-container {
    width: 100%;
    margin: 5rem auto 0;
}

.gallery-slider-content {
    margin: 0 auto;
    width: 90%;
}

.gallery-img-container img {
    width: 100%;
}

.gallery-img-details {
    margin: 0.5rem 0.5rem;
}

.gallery-slide {
    transform: scale(0.75);
    transition: transform 500ms;
    opacity: 0.25;
}

.gallery-active-slide {
    transform: scale(1.0);
    opacity: 1;
}

.arrow {
    background-color: transparent;
    position: absolute;
    cursor: pointer;
    z-index: 10;
}

.arrow svg {
    transition: color 100ms;
}

.arrow svg:hover {
    color: rgb(255, 102, 0);
}

.next {
    right: 0%;
    top: 50%;
}

.prev {
    left: 0%;
    top: 50%;
}

.gallery-img-details {
    line-height: 1.65rem;
    text-align: center;
}

.gallery-img-details {
    font-size: 1.3rem;
    margin: 10px 0 0;
}

.gallery-img-details p {
    margin: 0;
}

.gallery-img-details h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 5px 0;
}

@media screen and (max-width:1300px) {

    .gallery-img-details {
        line-height: 1.45rem;
        text-align: center;
    }

    .gallery-img-details p {
        font-size: 1.1rem;
    }

    .gallery-img-details h3 {
        font-size: 1.2rem;
        font-weight: 500;
        margin: 5px 0;
    }
}

@media screen and (max-width:900px) {

    .gallery-img-details {
        line-height: 1.25rem;
        text-align: center;
    }
    
    .gallery-img-details p {
        font-size: 0.9rem;
        margin: 0;
    }

    .gallery-img-details h3 {
        font-size: 1.1rem;
        font-weight: 500;
        margin: 3px 0;
    }
}

@media screen and (max-width:800px) {

    .gallery-img-details {
        line-height: 1.05rem;
        text-align: center;
    }

    .gallery-img-details p {
        font-size: 0.8rem;
        margin: 0;
    }

    .gallery-img-details h3 {
        font-size: 0.9rem;
        font-weight: 500;
        margin: 3px 0;
    }
}

@media screen and (max-width:800px) {
    .gallery-slider-content {
        margin: 0 auto;
        width: 75%;
    }
}