.class-feature{
    background-color: rgb(211, 211, 211, 0.5);
    border-radius: 150px;
    width: fit-content;
    padding: 1% 2%;
}

.feature-content{
    font-size: 1.3rem;
    color: orangered;
    padding: 0;
    margin: 0;
}

.class-feature:hover{
    cursor: pointer;
    background-color: rgb(255, 102, 0);
    color: white;
}

.class-feature:hover .feature-content{
    color: white;
}

@media screen and (max-width: 1600px){
    .feature-content{
        font-size: 1.1rem;
    }
}
@media screen and (max-width: 1300px){
    .feature-content{
        font-size: 0.9rem;
    }
}
@media screen and (max-width: 1000px){
    .feature-content{
        font-size: 0.6rem;
    }
}
@media screen and (max-width: 800px){
    .feature-content{
        font-size: 0.8rem;
    }
}